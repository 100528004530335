.body {
    font-size: 20px;
    width: 100vw;
    min-height: calc(100vh - 80px);
    height: 100%;
    font-size: 20px;
    background-color: darkgreen;
    padding-bottom: 20px;
}

.title_container {
    width: 100%;
    padding: 40px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.title {
    font-size: 1.4em;
    color: white;
    font-style: italic;
    font-weight: 600;
}

.data_container {
    width: calc(100vw - 140px);
    height: 100%;
    background-color: white;
    border-radius: 20px;
    padding: 20px;
    margin: 0 50px;
    position: relative;
}

.button_back {
    background-color: darkviolet;
    border: 1px solid darkviolet;
    border-radius: 15px;
    display: flex;
    align-items: center;
    column-gap: 10px;
    padding: 5px 20px;
    font-size: 1em;
    color: white;
    position: absolute;
    left: 70px;
}

.button_back:hover {
    cursor: pointer;
    box-shadow: 2px 2px 5px rgba(255, 255, 255, 0.5);
}

.back {
    width: 20px;
    pointer-events: none;
}

.data_header {
    display: grid;
    grid-template-columns: 0.7fr 0.7fr 0.6fr 0.6fr 1fr 1.5fr 4fr;
    column-gap: 10px;
}

.data_title {
    font-size: 0.8em;
    color: rgba(0, 0, 0, 0.5);
    margin-left: 5px;
}

.data {
    border: 1px solid darkviolet;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding: 10px;
    font-size: 0.9em;
}

.center {
    justify-content: center;
}

.cards_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 10px;
    row-gap: 10px;
    padding: 30px;
    border: 1px solid darkviolet;
    border-radius: 10px;
    width: 100%;
    background-image: url('../../../assets/images/tapiz.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}

.info_title {
    font-size: 0.8em;
    color: rgba(0, 0, 0, 0.5);
    margin-left: 5px;
}

.info {
    margin-top: 20px;
    display: flex;
    column-gap: 10px;
    width: 100%;
}

.right {
    padding-bottom: 23px;
}

.response {
    width: 100%;
    min-height: 49vh;
    height: 100%;
    border: 1px solid darkviolet;
    border-radius: 10px;
}

.textarea {
    width: 100%;
    border-radius: 10px;
    padding: 10px;
    border: none;
    resize: none;
    height: calc(100% - 20px);
    font-size: 0.9em;
    text-align: justify;
}

.textarea:focus-visible {
    outline: none !important;
}
