.body {
  width: 100vw;
  height: 100%;
  min-height: calc(100vh - 60px);
  background: linear-gradient(
    225deg,
    rgba(193, 63, 255, 0.51) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  position: relative;
  display: flex;
  justify-content: space-between;
  column-gap: 100px;
  font-size: 20px;
  padding-bottom: 80px;
  overflow: hidden;
}

.img {
  width: 15.625vw;
  height: calc(15.625vw * 1.7);
  border-radius: 10px;
  padding: 5px;
  background-color: white;
  margin: 10px 10px 5px 10px;
  opacity: 0.4;
}

.cards {
  display: flex;
  flex-direction: column;
  position: absolute;
}

.left,
.right {
  min-width: calc(15.625vw + 30px);
  position: relative;
  min-height: 100vh;
}

.title {
  margin: 50px 0;
  font-size: 2em;
  font-weight: 900;
  text-align: center;
  color: darkviolet;
  text-shadow: 20px 20px rgba(0, 0, 0, 0.05);
}

.content p {
  margin: 20px 0;
  text-indent: 100px;
  line-height: 150%;
}

ol {
  margin: 0 50px;
  list-style-type: lower-roman;
  list-style: square;
}

li {
  text-align: justify;
  line-height: 150%;
  margin: 15px 0;
  font-style: italic;
}

@media (max-width: 1366px) {
  .body {
    column-gap: 50px;
    font-size: 18px;
  }
}

@media (max-width: 1280px) {
  .body {
    font-size: 17px;
  }
}

@media (max-width: 768px) {
  .body {
    font-size: 14px;
    column-gap: 20px;
  }
  .content ol {
    margin: 0 20px;
  }
  .title {
    margin: 30px 0;
  }
}

@media (max-width: 480px) {
  .left,
  .right {
    display: none;
  }
  .body {
    font-size: 12px;
    padding-bottom: 40px;
  }
  .content {
    margin: 0 20px;
  }
}
