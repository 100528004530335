.body {
  width: 15vw;
  z-index: 0;
  min-height: calc(100vh - 60px);
  position: sticky;
  top: 0;
  border-right: 3px solid rgba(0, 0, 0, 0.2);
}

.menu_container {
  padding: 40px 20px;
}

.item {
  padding: 7px 0 7px 10px;
  width: calc(100% - 10px);
  border-radius: 20px;
  margin: 10px 0;
}

.item:hover {
  cursor: pointer;
}

.active {
  background-color: darkviolet;
  color: white;
}

.button_container {
  width: 100%;
  margin-top: 60px;
  display: flex;
  justify-content: center;
}

.button_consult {
  padding: 10px 30px;
  border: none;
  background-color: indigo;
  color: white;
  border-radius: 20px;
  font-weight: 600;
}

.button_consult:hover {
  cursor: pointer;
  box-shadow: 0 0 10px darkviolet;
  transform: scale(1.1);
  transition: all 0.2s;
}

.button_consult:disabled {
  cursor: no-drop;
  background-color: gray;
  color: lightgray;
  transform: scale(1);
  box-shadow: none;
}
