.body {
  width: 100vw;
  height: calc(100vh - 60px);
  background: linear-gradient(
    252.44deg,
    rgba(193, 63, 255, 0.51) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  position: relative;
  font-size: 20px;
  overflow: hidden;
}

.header {
  display: flex;
  justify-content: space-between;
}

.frase_container {
  width: 50%;
}

.content {
  margin: 50px 100px;
}

.title1,
.title2 {
  font-size: 3.4em;
  font-weight: 900;
  white-space: nowrap;
}

.title2 {
  color: darkviolet;
}

.frase_container {
  margin-top: 60px;
}

.title_frase {
  font-size: 1.2em;
  font-weight: 600;
}

.frase {
  margin-top: 10px;
}

.line {
  margin-top: 10px;
  width: 200px;
  height: 4px;
  background: linear-gradient(to right, darkviolet, transparent 100%);
}

.swiper_container {
  width: 100%;
  height: calc(100vh - 300px);
  margin-top: 50px;
  overflow: hidden;
}

@media (max-width: 1550px) {
  .title1,
  .title2 {
    font-size: 2.4em;
  }
  .frase_container {
    margin-top: 10px;
  }
  .boxes {
    margin-top: 30px;
    width: 100%;
  }
  .box_body p {
    font-size: 0.6em;
  }
  .content {
    margin: 20px 50px;
  }
}

@media (max-width: 1024px) {
  .title1,
  .title2 {
    font-size: 2em;
  }
  .frase_container {
    width: 55%;
  }
  .title_frase {
    font-size: 1.1em;
  }
}

@media (max-width: 768px) {
  .body {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .body {
    font-size: 12px;
  }
  .content {
    margin: 30px 20px;
  }
  .header {
    flex-direction: column;
  }
  .frase_container {
    margin-top: 20px;
    width: 100%;
  }
  .title_frase {
    font-size: 1em;
    font-weight: 700;
  }
  .swiper_container {
    margin-top: 0px;
  }
}
