.body {
  width: 100%;
  min-height: calc(100vh - 60px);
  padding: 0 20px;
  height: 100%;
  font-size: 20px;
}

.result_container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 20px;
}

.title {
  width: 100%;
  padding: 20px 0;
  text-align: center;
  font-size: 1.4em;
  font-weight: 700;
}

.title_invertida {
  padding: 1px 10px;
  margin-left: 10px;
  background-color: red;
  color: white;
  font-size: 0.7em;
  border-radius: 10px;
}

.card {
  width: 120px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  float: left;
  margin-right: 15px;
}

.card_invertida {
  transform: rotate(180deg);
  box-shadow: -5px -5px 10px rgba(0, 0, 0, 0.2);
}

.card_name {
  width: 100%;
  padding: 7px 0;
  display: flex;
  justify-content: center;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 20px 20px 0 0;
}

.concept_header {
  min-height: 206px;
  max-height: 50vh;
  overflow-y: auto;
}

.stdtext {
  font-size: 0.6em;
}

.boldtext {
  font-size: 0.6em;
  font-weight: 600;
}

.title_answer {
  margin: 20px 10px;
  padding: 5px 0;
  width: calc(100% - 20px);
  background-color: darkviolet;
  color: white;
  text-align: center;
}

.info_container {
  width: calc(100% - 20px);
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0 0 20px 20px;
}
