.body {
    font-size: 20px;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    z-index: 3;
    display: flex;
    justify-content: space-between;
}

.body_content {
    margin: 20px;
}

.circle {
    width: 70px;
    height: 70px;
    display: none;
    background-color: darkviolet;
    border-radius: 50%;
}

.sobrecircle {
    position: absolute;
    top: 0;
    left: 0;
    width: 70px;
    height: 70px;
    background: linear-gradient(120deg, white, transparent 80%);
    border-radius: 50%;
    z-index: 2;
}

.circle_show {
    display: flex;
    align-items: center;
    justify-content: center;
    animation: rotate 4s ease-out 1;
    transform-style: preserve-3d;
}

@keyframes rotate {
    from {
        transform: rotateXY(0deg) rotateZ(0deg);
    }
    to {
        transform: rotateY(360deg) rotateZ(1440deg);
    }
}

.img_love {
    height: calc(100vh * 0.6);
    border-radius: 0 20px 20px 0;
    mask-image: linear-gradient(to left, rgba(0, 0, 0, 1) 70%, rgba(0, 0, 0, 0));
    mask-size: 100% 100%;
    mask-repeat: no-repeat;
    mask-position: top;
    opacity: 0;
    transition: opacity 0.5s;
}

.show {
    opacity: 1;
    transition: opacity ease-in 3s;
}

.title_container {
    display: flex;
    align-items: center;
    column-gap: 15px;
    position: relative;
}

.title {
    font-size: 2em;
    font-weight: 900;
}

.line {
    width: 200px;
    height: 4px;
    background: linear-gradient(to right, darkviolet, transparent 100%);
}

.parrafo {
    margin-left: 85px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 20px;
    height: 90%;
    margin-right: -100px;
}

.parrafo p {
    margin-bottom: 10px;
    font-size: 1.2em;
    font-weight: 400;
    font-style: italic;
    color: rgba(0, 0, 0, 0.7);
}

@media (max-width: 1366px) {
    .parrafo {
        height: 84%;
    }
    .parrafo p {
        font-size: 1em;
    }
}

@media (max-width: 1280px) {
    .parrafo {
        margin-right: -140px;
        background: none;
    }
    .parrafo p {
        font-size: 1.1em;
        z-index: 5;
    }
}

@media (max-width: 1024px) {
    .body {
        flex-direction: column;
        height: 100%;
    }
    .parrafo {
        font-size: 1.3em;
        margin: 20px 85px -150px 85px;
    }
    .img_love {
        width: 100%;
        height: fit-content;
        border-radius: 0 0 20px 20px;
        mask-image: linear-gradient(to top, rgba(0, 0, 0, 1) 70%, rgba(0, 0, 0, 0));
    }
    .title {
        font-size: 1.8em;
    }
    .body_content {
        min-height: 380px;
        height: 100%;
    }
}

@media (max-width: 768px) {
    .body {
        font-size: 14px;
    }
    .body_content {
        min-height: 200px;
    }
    .parrafo {
        margin: 20px 40px -200px;
    }
}

@media (max-width: 480px) {
    .body {
        font-size: 8px;
    }
    .parrafo {
        margin: 10px 0px -200px;
    }
    .line {
        width: 125px;
    }
    .body_content {
        min-height: 18vh;
        margin: 20px 0;
    }
    .circle {
        width: 52px;
        height: 52px;
    }
}
