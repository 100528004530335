.body {
  display: flex;
}

.manualTres-body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 100px);
  padding: 20px 0;
  width: 100%;
  z-index: 0;
  background-image: url("../../../assets/images/tapiz.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}

.finish {
  transform: translate(-120%);
  transition: transform 0.5s;
}

.cards-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  column-gap: 10px;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.card {
  height: calc((100vh - 60px) / 3 - 30px);
  transition: all 0.2s;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  transform: rotate(0deg);
  transition: transform 0.5s;
}

.card:hover {
  cursor: pointer;
  transition: all 0.2s;
  filter: brightness(60%);
}

.card:hover ~ .name {
  transition: all 0.2s;
  opacity: 1;
}

.card-selected {
  filter: invert(0.4) sepia(1) hue-rotate(120deg);
}

.card-container {
  position: relative;
}

.name {
  position: absolute;
  top: 40%;
  left: 0px;
  font-size: 0.8em;
  font-weight: 700;
  color: white;
  text-align: center;
  width: 100%;
  opacity: 0;
  transition: all 0.2s;
  pointer-events: none;
}

.invert {
  position: absolute;
  bottom: 10px;
  left: 0px;
  width: 100%;
  display: none;
}

.invert-content {
  margin-left: 60%;
  width: 40px;
  height: 40px;
  background-color: rgba(255, 0, 0, 0.7);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.invert-content:hover {
  cursor: pointer;
  box-shadow: 0px 0px 10px white;
}

.select {
  height: 25px;
  width: 70%;
  position: absolute;
  top: 10px;
  left: 0px;
  background-color: rgba(34, 139, 34, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 10px 10px 0;
  display: none;
}

.select-selected {
  display: flex;
}

.selected {
  display: block;
}

.select span {
  font-size: 0.6em;
  font-weight: 600;
  color: white;
}

.invertida {
  transform: rotate(180deg);
  transition: transform 0.5s;
}

.result-body {
  width: 100%;
  height: calc(100vh - 60px);
}
