.card,
.menores {
    width: 6.51vw;
    height: calc(6.51vw * 1.8571);
    border-radius: 5px;
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.2);
    transition: all 1s;
}

/* .menores {
  background-color: white;
  padding: 0.95vh 0.325vw;
  width: 5.859375vw;
  height: calc(6vw * 1.8571);
} */

.invertida {
    transform: rotate(180deg);
    box-shadow: -5px -5px 10px rgba(0, 0, 0, 0.2);
    transition: all 1s;
}
