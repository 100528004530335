.body {
    padding: 50px;
    font-size: 20px;
    background-color: darkgreen;
}

.nowrap {
    white-space: nowrap;
}

tr {
    vertical-align: top;
}

td {
    font-size: 0.7em;
}

p {
    text-align: justify;
}

table {
    border-spacing: 5px;
    background-color: white;
    padding: 20px;
    border-radius: 0%;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
    width: 100%;
}

th {
    font-size: 0.7em;
    font-weight: 900;
}

td {
    padding: 5px;
    vertical-align: middle;
}

.gris {
    background-color: rgba(0, 0, 0, 0.1);
}

.white {
    background-color: white;
}

.title {
    font-size: 1.4em;
    font-weight: 900;
    text-align: center;
    margin-bottom: 40px;
    color: white;
}

.nofound {
    font-size: 1.4em;
    font-weight: 900;
    text-align: center;
    margin: 100px 0;
    color: rgba(0, 0, 0, 0.5);
}

.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border-left-color: #09f;
    animation: spin 0.8s linear infinite;
    margin-left: 10px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.icon_container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon {
    width: 30px;
    margin-top: auto;
    margin-bottom: auto;
}

.icon:hover {
    cursor: pointer;
}
