.navbar-body {
    width: 100%;
    height: 60px;
    font-size: 20px;
    padding: 0 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 5px 0 20px rgba(0, 0, 0, 0.2);
    z-index: 99;
    position: sticky;
    top: 0;
    background-color: white;
}

.logo-container {
    display: flex;
    align-items: center;
    column-gap: 10px;
}

.logo {
    width: 50px;
    height: 50px;
}

.tarot,
.online {
    font-size: 1.6em;
    font-weight: 900;
}

.online {
    color: darkviolet;
}

.menu-container {
    display: flex;
    align-items: center;
    column-gap: calc(100vw * 0.001);
}

.item {
    font-size: 0.8em;
    padding: 5px 25px;
    border-radius: 20px;
    white-space: nowrap;
}

.item:hover {
    cursor: pointer;
    transition: transform 0.2s;
    transform: scale(1.1);
}

.item-selected {
    background-color: darkviolet;
    color: white;
}

.link {
    text-decoration: none;
    color: rgba(0, 0, 0, 0.7);
}

.link:active {
    color: darkviolet;
}

.content-menu {
    position: relative;
    vertical-align: center;
    height: 100%;
}

.submenu {
    position: absolute;
    background-color: white;
    border-radius: 0 0 10px 10px;
    box-shadow: 5px 5px rgba(0, 0, 0, 0.2);
    top: 60px;
    padding: 10px 20px;
    display: none;
}

#consultas,
#clientes {
    padding: 20px 0;
}

#consultas:hover .submenu,
#clientes:hover .submenu {
    display: block;
}

#user {
    padding: 20px 0;
}

#user:hover .submenu {
    display: block;
}

.subitem {
    font-size: 0.8em;
    width: calc(100% - 40px);
    padding: 7px 20px;
    white-space: nowrap;
}

.subitem:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.1);
}

#submenuuser {
    margin-left: -80px;
}

.bars {
    width: fit-content;
    display: none;
}

.bar1,
.bar2,
.bar3 {
    width: 35px;
    height: 1px;
    border: 2px solid #504e4e;
    border-radius: 2px;
    transition: all 0.5s;
    background-color: #504e4e;
}

.bar1,
.bar2 {
    margin-bottom: 8px;
}

@media (max-width: 1024px) {
    .menu-container {
        position: absolute;
        flex-direction: column;
        align-items: flex-start;
        row-gap: 20px;
        top: 60px;
        right: 0px;
        background-color: white;
        padding: 20px;
        border-radius: 0 0 20px 20px;
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
        transform: translateX(100%);
        transition: transform 0.5s;
    }
    .menu-active {
        transform: translateX(0%);
        transition: transform 0.5s;
    }
    #consultas,
    #clientes,
    #user {
        padding: 0;
    }
    .bars {
        display: flex;
        flex-direction: column;
    }
    .bars:hover {
        cursor: pointer;
    }
    .bar1-active {
        transform: rotate(45deg) translateX(8px) translateY(10px);
        transition: all 0.5s;
    }
    .bar2-active {
        opacity: 0;
        transition: all 0.5s;
    }
    .bar3-active {
        transform: rotate(-45deg) translateX(8px) translateY(-10px);
        transition: all 0.5s;
    }
    .submenu {
        position: relative;
        z-index: 10;
        display: flex;
        flex-direction: column;
        width: fit-content;
        padding: 5px 0;
        display: none;
        top: 0;
        background: none;
        border-radius: none;
        box-shadow: none;
        margin-left: 20px;
    }
    .sublink {
        font-size: 1em;
        line-height: 1.5em;
    }
    #submenuuser {
        margin-left: 0px;
    }
    .navbar-body {
        padding: 0 20px;
        width: calc(100vw - 40px);
    }
}

@media (max-width: 480px) {
    .tarot,
    .online {
        font-size: 1em;
    }
    .navbar-body {
        padding: 0 20px;
        width: calc(100vw - 40px);
    }
    .circle {
        width: 20px;
        height: 50px;
    }
}
