@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;600;700;90&family=Pacifico&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100vw;
    overflow-x: hidden;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
    padding: 0;
    margin: 0;
    font-family: 'Montserrat', sans-serif;
}

.spinner {
    border: 2px solid #ffffff;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border-left-color: #09f;
    opacity: 0.8;
    animation: spin 0.75s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.text-border {
    -webkit-text-stroke: 2px white;
}
