.body {
  height: calc(100vh - 60px);
  width: 100vw;
  background-image: url("../../../../assets/images/tapiz.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  font-size: 20px;
  display: flex;
  flex-direction: column;
}

.title_container {
  width: 100%;
  padding: 10px 0;
  display: flex;
  justify-content: center;
}

.title {
  font-size: 1.4em;
  color: white;
  font-style: italic;
  font-weight: 600;
}

.label {
  font-size: 0.8em;
  white-space: nowrap;
  color: rgba(0, 0, 0, 0.7);
}

.container {
  width: 100%;
  margin-top: 20px;
  display: flex;
  column-gap: 20px;
}

.panel {
  background-color: rgb(248, 173, 248);
  width: 40%;
  border-radius: 20px;
  padding: 20px;
  margin-left: 20px;
}

.inputs_container {
  display: grid;
  grid-template-columns: 3fr 1fr;
  column-gap: 20px;
}

.input {
  width: calc(100% - 20px);
  height: 37px;
  border: none;
  padding: 0 10px;
  border-radius: 5px;
}

.input:focus-visible {
  outline: none !important;
  border: 1px solid darkviolet;
  background-color: rgb(251, 240, 242);
}

.input:disabled {
  background-color: rgb(232, 240, 254);
}

.textarea {
  width: calc(100% - 20px);
  height: 21vh;
  border: none;
  padding: 10px;
  border-radius: 5px;
  resize: none;
}

.textarea:disabled {
  background-color: rgb(232, 240, 254);
}

.textarea:focus-visible {
  outline: none !important;
  border: 1px solid darkviolet;
}

.buttons_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 5px;
  row-gap: 5px;
  margin-top: 10px;
  min-height: 28px;
}

.button {
  padding: 5px 15px;
  background-color: darkviolet;
  color: white;
  font-size: 0.7em;
  border-radius: 20px;
  border: none;
  white-space: nowrap;
}

.button:hover {
  cursor: pointer;
  background-color: rgb(182, 20, 252);
}

.button:disabled {
  display: none;
}
