.body {
  width: 100vw;
  height: calc(100vh - 60px);
  background-image: url("../../assets/images/homepage/background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

.login {
  width: 30vw;
  height: 45vh;
  background-color: rgb(252, 172, 252);
  border-radius: 20px;
  padding: 20px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 1);
}

.title {
  font-size: 1.3em;
  font-weight: 700;
  text-align: center;
  color: darkviolet;
}

.inputs_container {
  padding: 30px;
}

.label {
  font-size: 0.8em;
  color: rgba(0, 0, 0, 0.5);
}

.input_container {
  margin-bottom: 20px;
}

.input {
  width: 100%;
  height: 37px;
  margin-top: 5px;
  border: none;
  padding-left: 10px;
}

.input:focus-visible {
  outline: none !important;
  border: 1px solid darkviolet;
  background-color: lightyellow;
}

.passIcons {
  position: absolute;
  top: 31px;
  left: 94%;
  width: 24px;
}

.passIcons:hover {
  cursor: pointer;
}

.buttons_container {
  display: flex;
  justify-content: space-around;
}

.button_enter {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 33px;
  width: 152px;
  background-color: darkviolet;
  border: 2px solid darkviolet;
  color: white;
  border-radius: 20px;
}

.button_enter:hover {
  cursor: pointer;
  transform: scale(1.1);
  transition: all 0.2s;
}

.button_cancel {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 152px;
  height: 33px;
  background-color: white;
  border: 2px solid darkviolet;
  border-radius: 20px;
  color: darkviolet;
}

.button_cancel:hover {
  cursor: pointer;
  transform: scale(1.1);
  transition: all 0.2s;
}

.error {
  font-size: 0.7em;
  color: red;
  text-align: center;
  margin-top: 10px;
}
