.body {
  width: 100vw;
  height: 100%;
  min-height: calc(100vh - 60px);
  background: linear-gradient(
    225deg,
    rgba(193, 63, 255, 0.51) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}
